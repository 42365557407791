<template>
  <div>
    <CRow>
      <FlashMessage :position="'right top'" style="z-index: 2000;"></FlashMessage>
      <CCol xl="12" class="padding-5">
        <CCard class="padding-0 margin-0" style="background-color: #CEE3F5;" v-on:click="changeAirbase()">
          <CCardBody style="overflow: auto;" class="padding-10">
            <SvgPanZoom
                style="width: 100%; border:0px solid black; background-color: #CEE3F5;"
                :zoomEnabled="true"
                :controlIconsEnabled="true"
                :mouseWheelZoomEnabled="true"
                :dblClickZoomEnabled="false"
                :panEnabled="true"
                :fit="true"
                :center="true"
                minZoom="0.5"
                maxZoom="21"
                :onZoom="zoomAction"
            >
              <MapsIndonesia v-if="isLoadingMap" style="height: 500px;" :zoommap="zoomMaps"/>
              <MapsIndonesia v-if="!isLoadingMap" style="height: 500px;" 
                :zoommap="zoomMaps" 
                :assetlist="dtassetlist"
                :mapsdata="dtmapsdata"
                :descmapsdata="dtdescmapsdata"
                :vismapsdata="dtvismapsdata"
                :airbaseid="dtairbaseid"
                :ref="'mapairbase'"
                />
            </SvgPanZoom>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CModal
      title="Modal Air Base"
      :show.sync="modalAirbase"
      :closeOnBackdrop="false"
      size="xl"
    >
      <CCol v-if="isMLoading" xl="12" style="text-align: center;">
        <CSpinner
          color="info"
        />
      </CCol>
      <div v-if="dtairbaseid.type=='awos' && !isMLoading && type_detail=='awosradar'">
        <CRow v-if="runways.length>0" class="margin-0 d-flex justify-content-center">
          <!-- Col 1 -->
          <CCol v-for="(ks, ids) in showdash" :key="ids" xl="3" class="padding-0">
            <CCol v-if="getViewRunway(ks)" xl="12" class="text-center padding-0 font-dawos" style="line-height: 1;">
              <CRow class="col-xl-12 margin-0 padding-0">
                <CCol xl="12" class="padding-0">
                  <span class="header-title card-title font-weight-bold text-center col-xl-12">Magnetic Winds {{runways[getIndexShow(ks)]}} - knots</span>
                </CCol>
                <CCol xl="12" class="padding-0">
                  <small class="col-xl-12 text-dark">Active</small>
                </CCol>
                <CCol xl="12" class="padding-0 mt-2">
                  <MetarAwosRadar :arrow="arrow_rotate[getIndexShow(ks)]" :knot="knot_val[getIndexShow(ks)]" style="width:200px; height:200px; line-height: 1.5; text-align: left; margin: 0 auto;"/>
                </CCol>
              </CRow>
              <CRow class="mt-1 col-xl-12 margin-0 padding-0">
                <CCol xl="1" class="padding-0"></CCol>
                <CCol xl="2" class="padding-0"><small>Gust</small></CCol>
                <CCol xl="3" class="padding-0"><small>Variable Winds</small></CCol>
                <CCol xl="2" class="padding-0"><small>Cross</small></CCol>
                <CCol xl="2" class="padding-0"><small>Head/Tail</small></CCol>
                <CCol xl="2" class="padding-0"></CCol>

                <CCol xl="1" class="padding-0"></CCol>
                <CCol xl="2" class="padding-0">
                  <span class="font-weight-bold font-07">-</span>
                </CCol>
                <CCol xl="3" class="padding-0">
                  <span class="font-weight-bold font-07">-</span>
                </CCol>
                <CCol xl="2" class="padding-0">
                  <span class="font-weight-bold font-07">-</span>
                </CCol>
                <CCol xl="2" class="padding-0">
                  <span class="font-weight-bold font-07">-</span>
                </CCol>
                <CCol xl="2" class="padding-0"></CCol>
              </CRow>
              <CRow class="mt-1 col-xl-12 margin-0 padding-0">
                <CCol xl="2" class="padding-0"></CCol>
                <CCol xl="2" class="padding-0"><small>Speed</small></CCol>
                <CCol xl="2" class="padding-0"><small>Direction</small></CCol>
                <CCol xl="1" class="padding-0"><small>Min</small></CCol>
                <CCol xl="1" class="padding-0"><small>Max</small></CCol>
                <CCol xl="2" class="padding-0"><small>Cross</small></CCol>
                <CCol xl="2" class="padding-0"><small>Head/Tail</small> </CCol>

                <CCol xl="2" class="padding-0"><small class="font-weight-bold">2 Min.</small></CCol>
                <CCol xl="2" class="padding-0">
                  <span :class="{'font-weight-bold':true, 'font-manual':valclr(awosdata.runways[getIndexShow(ks)].wind_speed_source)}">
                    {{valnum(awosdata.runways[getIndexShow(ks)].wind_speed)}}
                  </span>
                </CCol>
                <CCol xl="2" class="padding-0">
                  <span :class="{'font-weight-bold':true, 'font-manual':valclr(awosdata.runways[getIndexShow(ks)].wind_direction_source)}">
                    {{valnum(awosdata.runways[getIndexShow(ks)].wind_direction)}}
                  </span>
                </CCol>
                <CCol xl="1" class="padding-0">
                  <span class="font-weight-bold font-07 font-blues">-</span>
                </CCol>
                <CCol xl="1" class="padding-0">
                  <span class="font-weight-bold font-07 font-oranges">-</span>
                </CCol>
                <CCol xl="2" class="padding-0">
                  <span class="font-weight-bold font-07">-</span>
                </CCol>
                <CCol xl="2" class="padding-0">
                  <span class="font-weight-bold font-07">-</span>
                </CCol>
              </CRow>
              <CRow class="mt-2 col-xl-12" style="line-height: 1.2;">
                <CCol xl="12" class="header-title padding-0">
                  <small class="font-weight-bold">
                    Visibility {{runways[getIndexShow(ks)]}} - <span :class="{'font-manual':valclr(awosdata.runways[getIndexShow(ks)].visibility_source)}">
                      {{valnum(awosdata.runways[getIndexShow(ks)].visibility)}}
                    </span> {{valstr(awosdata.runways[getIndexShow(ks)].visibility_unit)}}
                  </small>
                </CCol>
                <CCol xl="1" class="padding-0"></CCol>
                <CCol xl="2" class="padding-0"><small>Minute</small></CCol>
                <CCol xl="3" class="padding-0"><small>Average</small></CCol>
                <CCol xl="3" class="padding-0"><small>Min</small></CCol>
                <CCol xl="3" class="padding-0"><small>Max</small></CCol>

                <CCol xl="1" class="padding-0"></CCol>
                <CCol xl="2" class="padding-0"><small>1 Min.</small></CCol>
                <CCol xl="3" class="padding-0">
                  <span class="font-weight-bold font-09 font-greys">{{valnum(awosdata.runways[getIndexShow(ks)].visibility_modified[1].average)}}</span>
                </CCol>
                <CCol xl="3" class="padding-0">
                  <span class="font-weight-bold font-09 font-blues">{{valnum(awosdata.runways[getIndexShow(ks)].visibility_modified[1].min)}}</span>
                </CCol>
                <CCol xl="3" class="padding-0">
                  <span class="font-weight-bold font-09 font-oranges">{{valnum(awosdata.runways[getIndexShow(ks)].visibility_modified[1].max)}}</span>
                </CCol>

                <CCol xl="1" class="padding-0"></CCol>
                <CCol xl="2" class="padding-0"><small>2 Min.</small></CCol>
                <CCol xl="3" class="padding-0">
                  <span class="font-weight-bold font-09 font-greys">{{valnum(awosdata.runways[getIndexShow(ks)].visibility_modified[2].average)}}</span>
                </CCol>
                <CCol xl="3" class="padding-0">
                  <span class="font-weight-bold font-09 font-blues">{{valnum(awosdata.runways[getIndexShow(ks)].visibility_modified[2].min)}}</span>
                </CCol>
                <CCol xl="3" class="padding-0">
                  <span class="font-weight-bold font-09 font-oranges">{{valnum(awosdata.runways[getIndexShow(ks)].visibility_modified[2].max)}}</span>
                </CCol>

                <CCol xl="1" class="padding-0"></CCol>
                <CCol xl="2" class="padding-0"><small>10 Min.</small></CCol>
                <CCol xl="3" class="padding-0">
                  <span class="font-weight-bold font-09 font-greys">{{valnum(awosdata.runways[getIndexShow(ks)].visibility_modified[10].average)}}</span>
                </CCol>
                <CCol xl="3" class="padding-0">
                  <span class="font-weight-bold font-09 font-blues">{{valnum(awosdata.runways[getIndexShow(ks)].visibility_modified[10].min)}}</span>
                </CCol>
                <CCol xl="3" class="padding-0">
                  <span class="font-weight-bold font-09 font-oranges">{{valnum(awosdata.runways[getIndexShow(ks)].visibility_modified[10].max)}}</span>
                </CCol>
              </CRow>
              <CRow class="mt-2 col-xl-12" style="line-height: 1.2;">
                <CCol xl="12" class="header-title padding-0">
                  <small class="font-weight-bold">
                    RVR {{runways[getIndexShow(ks)]}} - <span :class="{'font-manual':valclr(awosdata.runways[getIndexShow(ks)].rvr_source)}">
                      {{valnum(awosdata.runways[getIndexShow(ks)].rvr)}}
                    </span> {{valstr(awosdata.runways[getIndexShow(ks)].rvr_unit)}}
                  </small>
                </CCol>
                <CCol xl="1" class="padding-0"></CCol>
                <CCol xl="2" class="padding-0"><small>Minute</small></CCol>
                <CCol xl="3" class="padding-0"><small>Average</small></CCol>
                <CCol xl="3" class="padding-0"><small>Min</small></CCol>
                <CCol xl="3" class="padding-0"><small>Max</small></CCol>

                <CCol xl="1" class="padding-0"></CCol>
                <CCol xl="2" class="padding-0"><small>1 Min.</small></CCol>
                <CCol xl="3" class="padding-0">
                  <span class="font-weight-bold font-09 font-greys">{{valnum(awosdata.runways[getIndexShow(ks)].rvr_modified[1].average)}}</span>
                </CCol>
                <CCol xl="3" class="padding-0">
                  <span class="font-weight-bold font-09 font-blues">{{valnum(awosdata.runways[getIndexShow(ks)].rvr_modified[1].min)}}</span>
                </CCol>
                <CCol xl="3" class="padding-0">
                  <span class="font-weight-bold font-09 font-oranges">{{valnum(awosdata.runways[getIndexShow(ks)].rvr_modified[1].max)}}</span>
                </CCol>

                <CCol xl="1" class="padding-0"></CCol>
                <CCol xl="2" class="padding-0"><small>2 Min.</small></CCol>
                <CCol xl="3" class="padding-0">
                  <span class="font-weight-bold font-09 font-greys">{{valnum(awosdata.runways[getIndexShow(ks)].rvr_modified[2].average)}}</span>
                </CCol>
                <CCol xl="3" class="padding-0">
                  <span class="font-weight-bold font-09 font-blues">{{valnum(awosdata.runways[getIndexShow(ks)].rvr_modified[2].min)}}</span>
                </CCol>
                <CCol xl="3" class="padding-0">
                  <span class="font-weight-bold font-09 font-oranges">{{valnum(awosdata.runways[getIndexShow(ks)].rvr_modified[2].max)}}</span>
                </CCol>

                <CCol xl="1" class="padding-0"></CCol>
                <CCol xl="2" class="padding-0"><small>10 Min.</small></CCol>
                <CCol xl="3" class="padding-0">
                  <span class="font-weight-bold font-09 font-greys">{{valnum(awosdata.runways[getIndexShow(ks)].rvr_modified[10].average)}}</span>
                </CCol>
                <CCol xl="3" class="padding-0">
                  <span class="font-weight-bold font-09 font-blues">{{valnum(awosdata.runways[getIndexShow(ks)].rvr_modified[10].min)}}</span>
                </CCol>
                <CCol xl="3" class="padding-0">
                  <span class="font-weight-bold font-09 font-oranges">{{valnum(awosdata.runways[getIndexShow(ks)].rvr_modified[10].max)}}</span>
                </CCol>

                <CCol xl="12" class="padding-0 mt-1">
                  <span><small>Trend: </small><b>-</b><small class="ml-2">RLS: </small> <b>-</b></span>
                </CCol>
              </CRow>
            </CCol>
          
            <!-- Col 2 -->
            <CCol v-if="getViewLightning(ks)" xl="12" class="text-center padding-0 font-dawos" style="line-height: 1.2;">
              <CRow class="col-xl-12 margin-0 padding-0">
                <CCol xl="12" class="padding-0">
                  <span class="header-title card-title font-weight-bold text-center col-xl-12">Lightning {{runways[getIndexShow(ks)]}}</span>
                </CCol>
                <CCol xl="12" class="padding-0 mt-2">
                  <WindDirection :direction="valarr(awosdata.runways[getIndexShow(ks)].lightning_direction)" style="width:200px; height:200px; line-height: 1.5; text-align: left; margin: 0 auto;"/>
                </CCol>
                <CCol xl="12" class="padding-0">
                  <span :class="{'font-weight-bold':true, 'font-09':true, 'font-oranges':(!valclr(awosdata.runways[getIndexShow(ks)].lightning_source)), 'font-manual':valclr(awosdata.runways[getIndexShow(ks)].lightning_source)}">
                    {{valstr(awosdata.runways[getIndexShow(ks)].lightning)}}
                  </span>
                </CCol>
              </CRow>
              <CRow class="mt-1 col-xl-12 margin-0 padding-0">
                <CCol xl="12" class="header-title padding-0">
                  <small class="font-weight-bold">Present Weather {{runways[getIndexShow(ks)]}}</small>
                </CCol>
                <CCol xl="12" class="padding-0">
                  <span :class="{'font-weight-bold':true, 'font-09':true, 'font-oranges':(!valclr(awosdata.runways[getIndexShow(ks)].present_weather_source)), 'font-manual':valclr(awosdata.runways[getIndexShow(ks)].present_weather_source)}">
                    {{valstr(awosdata.runways[getIndexShow(ks)].present_weather)}}
                  </span>
                </CCol>
              </CRow>
              <CRow class="mt-1 col-xl-12 margin-0 padding-0">
                <CCol xl="12" class="header-title padding-0">
                  <small class="font-weight-bold">Sky Condition {{runways[getIndexShow(ks)]}} - 100s of feet</small>
                </CCol>
                <CCol xl="12" class="padding-0">
                  <span :class="{'font-weight-bold':true, 'font-09':true, 'font-oranges':(!valclr(awosdata.runways[getIndexShow(ks)].sky_source)), 'font-manual':valclr(awosdata.runways[getIndexShow(ks)].sky_source)}">
                    {{valstr(awosdata.runways[getIndexShow(ks)].sky)}}
                  </span>
                </CCol>
              </CRow>
              <CRow class="mt-1 col-xl-12 margin-0 padding-0">
                <CCol xl="12" class="header-title padding-0">
                  <small class="font-weight-bold">Temperature {{runways[getIndexShow(ks)]}} - celcius</small>
                </CCol>

                <CCol xl="2" class="padding-0"></CCol>
                <CCol xl="3" class="padding-0"><small>Air Temp.</small></CCol>
                <CCol xl="3" class="padding-0"><small>Dew Point</small></CCol>
                <CCol xl="2" class="padding-0"><small>RH</small></CCol>
                <CCol xl="2" class="padding-0"></CCol>

                <CCol xl="2" class="padding-0"></CCol>
                <CCol xl="3" class="padding-0">
                  <span class="font-weight-bold font-09">
                    <span :class="{'font-manual':valclr(awosdata.runways[getIndexShow(ks)].temperature_source)}">
                      {{valnum(awosdata.runways[getIndexShow(ks)].temperature)}}
                    </span>{{valstr(awosdata.runways[getIndexShow(ks)].temperature_unit)}}
                  </span>
                </CCol>
                <CCol xl="3" class="padding-0">
                  <span class="font-weight-bold font-09">
                    <span :class="{'font-manual':valclr(awosdata.runways[getIndexShow(ks)].dew_point_source)}">
                      {{valnum(awosdata.runways[getIndexShow(ks)].dew_point)}}
                    </span>{{valstr(awosdata.runways[getIndexShow(ks)].dew_point_unit)}}
                  </span>
                </CCol>
                <CCol xl="2" class="padding-0">
                  <span class="font-weight-bold font-09">
                    <span :class="{'font-manual':valclr(awosdata.runways[getIndexShow(ks)].relative_humidity_source)}">
                      {{valnum(awosdata.runways[getIndexShow(ks)].relative_humidity)}}
                    </span> {{valstr(awosdata.runways[getIndexShow(ks)].relative_humidity_unit)}}
                  </span>
                </CCol>
                <CCol xl="2" class="padding-0"></CCol>
              </CRow>

              <CRow class="mt-1 col-xl-12 margin-0 padding-0">
                <CCol xl="12" class="header-title padding-0">
                  <small class="font-weight-bold">Air Pressure {{runways[getIndexShow(ks)]}} - {{valstr(awosdata.runways[getIndexShow(ks)].qnh_unit)}}</small>
                </CCol>

                <CCol xl="1" class="padding-0"></CCol>
                <CCol xl="3" class="padding-0"><small>QNH</small></CCol>
                <CCol xl="3" class="padding-0"><small>QFE</small></CCol>
                <CCol xl="4" class="padding-0"><small>Density Altitude:</small></CCol>
                <CCol xl="1" class="padding-0"></CCol>

                <CCol xl="1" class="padding-0"></CCol>
                <CCol xl="3" class="padding-0">
                  <span :class="{'font-weight-bold':true, 'font-11':true, 'font-manual':valclr(awosdata.runways[getIndexShow(ks)].qnh_source)}">
                    {{valnum(awosdata.runways[getIndexShow(ks)].qnh)}}
                  </span>
                </CCol>
                <CCol xl="3" class="padding-0">
                  <span class="font-weight-bold font-11">{{'-'}}</span>
                </CCol>
                <CCol xl="4" class="padding-0">
                  <span class="font-weight-bold font-11">
                    <span :class="{'font-manual':valclr(awosdata.runways[getIndexShow(ks)].density_altitude_source)}">
                      {{valnum(awosdata.runways[getIndexShow(ks)].density_altitude)}}
                    </span> {{valstr(awosdata.runways[getIndexShow(ks)].density_altitude_unit)}}
                  </span>
                </CCol>
                <CCol xl="1" class="padding-0"></CCol>
              </CRow>

              <CRow class="mt-1 col-xl-12 margin-0 padding-0">
                <CCol xl="12" class="header-title padding-0">
                  <small class="font-weight-bold">Precipitation {{runways[getIndexShow(ks)]}} - mm</small>
                </CCol>

                <CCol xl="2" class="padding-0"></CCol>
                <CCol xl="2" class="padding-0"><small>1 Hour</small></CCol>
                <CCol xl="2" class="padding-0"><small>3 Hour</small></CCol>
                <CCol xl="2" class="padding-0"><small>6 Hour</small></CCol>
                <CCol xl="2" class="padding-0"><small>24 Hour</small></CCol>
                <CCol xl="2" class="padding-0"></CCol>

                <CCol xl="2" class="padding-0"></CCol>
                <CCol xl="2" class="padding-0">
                  <span class="font-weight-bold font-09">-</span>
                </CCol>
                <CCol xl="2" class="padding-0">
                  <span class="font-weight-bold font-09">-</span>
                </CCol>
                <CCol xl="2" class="padding-0">
                  <span class="font-weight-bold font-09">-</span>
                </CCol>
                <CCol xl="2" class="padding-0">
                  <span class="font-weight-bold font-09">-</span>
                </CCol>
                <CCol xl="2" class="padding-0"></CCol>
              </CRow>
            </CCol>
          </CCol>
        </CRow>
        <CRow v-if="runways.length>0" class="mt-1 margin-0">
          <CCol xl="1" class="padding-0 pl-2">
              <small class="font-weight-bold">METAR :</small>
          </CCol>
          <CCol xl="11" class="padding-0">
              <span class="font-weight-bold">{{valstr(awosdata.metar)}}</span>
          </CCol>
        </CRow>
        <CRow v-if="runways.length==0" class="">
          <CCol xl="12" class="padding-0 text-center"><span>Not Found</span></CCol>
        </CRow>
      </div>
      <div v-if="dtairbaseid.type=='weather_radar' && !isMLoading && type_detail=='awosradar'">
        <CRow class="margin-0" v-if="radardata.length>0">
          <div style="width: 100%; height: 75vh; position: relative;">
            <panZoom :options="{minZoom: 1, maxZoom: 5}" @panstart="onPanStart" @zoom="onZoom" class="text-center" style="overflow: hidden; height: 75vh;">
              <carousel :per-page="1" :loop="true" easing="ease-in-out"
                :autoplay="true" :autoplayTimeout="3000" :paginationEnabled="false" 
                :mouseDrag="false" :speed="0" :value="0" :centerMode="true" :class="{'nonetrans': ispanzoom}">
                <slide v-for="(map, idx) in radardata" :key="idx">
                    <img :src="map" alt="" class="img-fluid" style="width:100%;">
                </slide>
              </carousel>
            </panZoom>
            <div class="btn-sidedash-radar" @click="showlegend" :style="isLegend?'right: 15%;':'right: 0;'">
              <v-icon :name="isLegend?'caret-right':'caret-left'" style="height: 100%; vertical-align: middle;"/>
            </div>
            <div v-if="isLegend" class="sidedash-radar" style="height: 75vh; width: 15%;">
              <carousel :per-page="1" :loop="true" easing="ease-in-out"
                :autoplay="true" :autoplayTimeout="3000" :paginationEnabled="false" 
                :mouseDrag="false" :speed="0" :value="0" :centerMode="true" class="col-xl-12">
                <slide v-for="(maplg, idl) in legenddata" :key="idl">
                  <img :src="maplg" alt="" style="width: 100%;">
                </slide>
              </carousel>
            </div>
          </div>
        </CRow>
        <CCol v-if="radardata.length==0" xl="12" class="padding-0 text-center"><span>Not Found</span></CCol>
      </div>
      <div v-if="!isMLoading && type_detail=='information'">
        <CRow v-if="!isMLoading" class="margin-0">
          <CCol xl="6" class="padding-0">
            <CRow class="margin-0">
              <CCol xl="3" class="padding-0 mb-2"><span>Name</span></CCol>
              <CCol xl="9" class="padding-0 mb-2"><span>: {{ form.name }}</span></CCol>
              <CCol xl="3" class="padding-0 mb-2"><span>Location</span></CCol>
              <CCol xl="9" class="padding-0 mb-2"><span>: {{ form.location }}</span></CCol>
              <CCol xl="3" class="padding-0 mb-2"><span>IATA</span></CCol>
              <CCol xl="9" class="padding-0 mb-2"><span>: {{ form.iata_code }}</span></CCol>
              <CCol xl="3" class="padding-0 mb-2"><span>ICAO</span></CCol>
              <CCol xl="9" class="padding-0 mb-2"><span>: {{ form.code }}</span></CCol>
              <CCol xl="3" class="padding-0 mb-2"><span>Coordinate</span></CCol>
              <CCol xl="9" class="padding-0 mb-2"><span>: </span></CCol>
              <CCol xl="1" class="padding-0 mb-2"></CCol><CCol xl="3" class="padding-0"><span>- Latitude</span></CCol>
              <CCol xl="8" class="padding-0 mb-2"><span>: {{ form.geo_lat }}</span></CCol>
              <CCol xl="1" class="padding-0 mb-2"></CCol><CCol xl="3" class="padding-0"><span>- Longitude</span></CCol>
              <CCol xl="8" class="padding-0 mb-2"><span>: {{ form.geo_lng }}</span></CCol>
              <CCol xl="3" class="padding-0 mb-2"><span>Elevation</span></CCol>
              <CCol xl="9" class="padding-0 mb-2"><span>: {{ form.elevation }}</span></CCol>
            </CRow>
          </CCol>
          <CCol xl="6" class="padding-0">
            <CRow class="margin-0">
              <CCol xl="3" class="padding-0 mb-2"><span>Runway</span></CCol>
              <CCol xl="9" class="padding-0 mb-2"><span>: {{ form.runway_size }}</span></CCol>
              <CCol xl="1" class="padding-0 mb-2"></CCol><CCol xl="3" class="padding-0"><span>- Direction</span></CCol>
              <CCol xl="8" class="padding-0 mb-2"><span>: {{ runwayfirst+'/'+runwaylast }}</span></CCol>
              <CCol xl="3" class="padding-0 mb-2"><span>Taxiway</span></CCol>
              <CCol xl="9" class="padding-0 mb-2"><span>: {{ form.taxiway_size }}</span></CCol>
              <CCol xl="3" class="padding-0 mb-2"><span>Apron</span></CCol>
              <CCol xl="9" class="padding-0 mb-2"><span>: {{ form.apron_area }}</span></CCol>
            </CRow>
          </CCol>
        </CRow>
      </div>
      <template #header>
        <CButtonGroup>
          <CButton :class="{'btn-time-disactive':type_detail!='awosradar', 'btn-time-active':type_detail=='awosradar'}" @click="changeDetail('awosradar')">{{ dtairbaseid.type=="awos"?'AWOS':'RADAR' }}</CButton>
          <CButton :class="{'btn-time-disactive':type_detail!='information', 'btn-time-active':type_detail=='information'}" @click="changeDetail('information')">information</CButton>
        </CButtonGroup>
        <h6 class="modal-title" style="width: 100%; text-align: right;">{{ dtairbaseid.name }}</h6>
        <CButtonClose @click="modalAirbase = false"/>
      </template>
      <template #footer>
        <CButton @click="modalAirbase = false" color="light" class="text-left">Close</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { Carousel, Slide } from 'vue-carousel';
import MapsIndonesia from '../components/MapsIndonesia'
import WindDirection from '../components/WindDirection'
import MetarAwosRadar from '../components/MetarAwosRadar'

export default {
  name: 'MapView',
  components: {
    MapsIndonesia,
    WindDirection,
    MetarAwosRadar,
    Carousel,
    Slide
  },
  data () {
    return {
      isLoadingMap: false, isMLoading: false, 
      modalAirbase: false,
      airradar: [],
      zoomMaps: [1],
      dtassetlist: [],
      dtmapsdata: [],
      dtdescmapsdata: [],
      dtvismapsdata: [],
      dtairbaseid: { uuid: '', id: '', type: '', name: '' },
      awosdata: null,
      runways: [],
      arrow_rotate: [0, 0],
      knot_val: [0, 0],
      showdash: [],
      radardata: [],
      legenddata: ['storage/legendcmax.png'],
      ispanzoom: true,
      isLegend: true, 
      type_detail: '',
      runwayfirst: '', runwaylast: '',
      form: {
        uuid: '',
        code: '',
        name: '',
        runway_size: '',
        taxiway_size: '',
        apron_area: '',
        latitude: '',
        longitude: '',
        elevation: '',
        runway: '',
        runway_modified: ['', ''],
        timezone: '',
        iata_code: '',
        location: '',
        geo_lat: '',
        geo_lng: '',
      },
      timezones: [
        { value: 'WIB', label: 'WIB (Waktu Indonesia Barat)' },
        { value: 'WITA', label: 'WITA (Waktu Indonesia Tengah)' },
        { value: 'WIT', label: 'WIT (Waktu Indonesia Timur)' },
      ],
    }
  },
  watch: {
    modalAirbase: {
      handler: function (val, oldVal) {
        if(!val){
          clearInterval(this.intervalAirbase)
          this.type_detail = ''
          this.runwayfirst = '', this.runwaylast = '',
          this.form = { 
            uuid: '', 
            code: '', 
            name: '',
            runway_size: '',
            taxiway_size: '',
            apron_area: '',
            latitude: '',
            longitude: '',
            elevation: '',
            runway: '',
            runway_modified: ['', ''],
            timezone: '',
            iata_code: '',
            location: '',
            geo_lat: '',
            geo_lng: '',
          }
          if(this.dtairbaseid.type=="awos") {
            this.awosdata = null
            this.runways = []
            this.arrow_rotate = [0, 0]
            this.knot_val = [0, 0]
            this.showdash = []
          } else if(this.dtairbaseid.type=="weather_radar") {
            this.radardata = []
            this.legenddata = ['storage/legendcmax.png']
            this.ispanzoom = true
            this.isLegend = true
          }
          this.dtairbaseid = { uuid: '', id: '', type: '', name: '' }
        }
      }
    },
  },
  created() {
    this.reRender()
  },
  beforeDestroy() {
    clearInterval(this.intervalAirbase)
  },
  methods: {
    ...mapActions('dashboard', ['indexADSH', 'storeAWOS', 'storeRADAR']),
    ...mapActions('airport', ['showAPT']),
    async reRender(){
      this.isLoadingMap = true
      this.airradar = []
      await this.indexADSH().then((response) => {
        this.airradar = response.radars.length > 0 ? response.radars : []
        this.setMapData()
        this.isLoadingMap = false
      }).catch((error) => {
        this.flashMessage.error({ title: 'Error', message: error })
        this.isLoadingMap = false
      })
    },
    setMapData() {
      this.airradar.forEach((item, i) => {
        var airport = item.airport ? item.airport.name+' ('+item.airport.code+')':''
        var airport_id = item.airport ? item.airport.id:''
        var airport_uuid = item.airport ? item.airport.uuid:''
        var stm = item.condition_modified?item.condition_modified:'-'
        var brand = item.brand?item.brand:'-'
        var lat = item.latitude?item.latitude:0
        var lmm = item.last_maintenance_modified?item.last_maintenance_modified:'-'
        var lng = item.longitude?item.longitude:0
        var type = item.type?item.type:''
        var typm = item.type_modified?item.type_modified:''
        var clr = this.getColor(item.condition, type)
        var bdclr = this.getborderColor(item.condition, type)
        this.dtassetlist[i] = { 
            id: item.id, name: item.name, brand: brand, status: stm, 
            last_maintenance: lmm, type: type, type_modified: typm, airbase: airport, airbase_id: airport_id, 
            airbase_uuid: airport_uuid, longitude: lng, latitude: lat, color: clr, bdrcolor: bdclr, scale: 1,
            key: i, keymap: 'map'+i, keydesc: 'desc'+i
        }
        this.dtdescmapsdata[i] = { x: parseFloat(lng)+24, y: parseFloat(lat)+4, width: '12%' }
        this.dtmapsdata[i] = { x: parseFloat(lng), y: parseFloat(lat), width: '1.5%' }
        this.dtvismapsdata[i] = false
      })
    },
    zoomAction(val) {
      this.zoomMaps = val
    },
    getColor(val, type) {
      // var clr = '#53aeb3'
      // if(!val) return clr
      // if(val=='active') { return clr }
      // else if(val=='maintenance') { return '#f39e38' }
      // else if(val=='unserviceable') { return '#ec575c' }
      // else { return clr }
      return '#53aeb3'
    },
    getborderColor(val, type) {
      // var clr = '#246666'
      // if(!val) return clr
      // if(val=='active') { return clr }
      // else if(val=='maintenance') { return '#ba7436' }
      // else if(val=='unserviceable') { return '#c2424b' }
      // else { return clr }
      return '#246666'
    },
    changeAirbase: function () {
      this.dtairbaseid = { 
        uuid: this.$refs.mapairbase.dtairbaseid.uuid, 
        id: this.$refs.mapairbase.dtairbaseid.id, 
        type: this.$refs.mapairbase.dtairbaseid.type, 
        name: this.$refs.mapairbase.dtairbaseid.name 
      }
      if(this.dtairbaseid.id) { this.type_detail = 'awosradar', this.modalAirbase = true, this.isMLoading = true }
      this.getAirbase()
      this.getInfoAirbase()
      this.intervalAirbase = setInterval(this.getAirbase, 10000)
    },
    async getAirbase() {
      if(this.dtairbaseid.id && this.dtairbaseid.type=="awos") {
        var form = { airport_id: this.dtairbaseid.id, update_preference: 0 }
        await this.storeAWOS(form).then((response) => {
          this.awosdata = response
          let objRunways = Object.keys(response.runways).map((key) => {
            return response.runways[key]
          })
          this.runways = Object.keys(response.runways).map((key) => {
            return key
          })
          this.runways.forEach((item, i) => {
            var ir = i+(i+1)-1
            var il = i+(i+1)
            this.showdash[ir] = 'ra:'+i+':'+item
            this.showdash[il] = 'lg:'+i+':'+item
          })
          this.awosdata.runways = objRunways
          if(this.runways.length>0){this.getRotate(objRunways)}
          this.isMLoading = false
        }).catch((error) => {
          this.flashMessage.error({ title: 'Error', message: error.statusText })
          this.isMLoading = false
        })
      } else if(this.dtairbaseid.id && this.dtairbaseid.type=="weather_radar") {
        if(this.isMLoading){
          this.radardata = []
          this.legenddata = ['storage/legendcmax.png']
          this.ispanzoom = true
          this.isLegend = true
        }
        var form = { airport_id: this.dtairbaseid.id, update_preference: 0 }
        await this.storeRADAR(form).then((response) => {
          this.radardata = response.map(function (obj) { return obj.image_radar })
          this.legenddata = response.map(function (obj) { return obj.image_legend })
          if(this.legenddata<1) { this.legenddata = ['storage/legendcmax.png'] }
          this.isMLoading = false
        }).catch((error) => {
          this.flashMessage.error({ title: 'Error', message: error.statusText })
          this.isMLoading = false
        })
      }
    },
    async getInfoAirbase() {
      if(this.dtairbaseid.uuid) {
        await this.showAPT(this.dtairbaseid.uuid).then((response) => {
          this.form = response
          this.runwayfirst = response.runway_modified?response.runway_modified[0]:''
          this.runwaylast = response.runway_modified?response.runway_modified[1]:''
          this.isMLoading = false
        }).catch((error) => {
          this.isMLoading = false
        })
      }
    },
    changeDetail(detail) {
      this.type_detail = detail
    },
    getRotate(val) {
      var arrow1 = val[0]?val[0].wind_direction:0
      var arrow2 = val[1]?val[1].wind_direction:0
      this.arrow_rotate = [arrow1, arrow2]

      var knot1 = val[0]?val[0].wind_speed:0
      var knot2 = val[1]?val[1].wind_speed:0
      this.knot_val = [knot1, knot2]
    },
    getViewRunway(val) {
      var type = val.split(":");
      if(type[0]=='ra') { return true } else { return false }
    },
    getViewLightning(val) {
      var type = val.split(":");
      if(type[0]=='lg') { return true } else { return false }
    },
    getIndexShow(val) {
      var index = val.split(":");
      return index[1]
    },
    valnum(v){
      if(!v) return 0
      else return v
    },
    valstr(v){
      if(!v) return '-'
      else return v
    },
    valarr(v){
      if(!v) return []
      else return v
    },
    valclr(v){
      if(!v) return false
      else if(v=='manual') return true
      else return false
    },
    onPanStart(e) {
      this.ispanzoom = false
    },
    onZoom(e) {
      this.ispanzoom = false
    },
    showlegend() {
      this.isLegend = !this.isLegend;
    },
  }
}
</script>
