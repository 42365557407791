<template>
    <div>
        <img src="storage/metar/compass/compass.svg" style="width:200px; height:200px; position: absolute;"/>
        <img src="storage/metar/compass/border_outside.svg" style="width:200px; height:200px; position: absolute;"/>

        <img v-if="compass.N" src="storage/metar/compass/n_compass.svg" style="width:200px; height:200px; position: absolute;"/>
        <img v-if="compass.NE" src="storage/metar/compass/ne_compass.svg" style="width:200px; height:200px; position: absolute;"/>
        <img v-if="compass.NW" src="storage/metar/compass/nw_compass.svg" style="width:200px; height:200px; position: absolute;"/>
        <img v-if="compass.E" src="storage/metar/compass/e_compass.svg" style="width:200px; height:200px; position: absolute;"/>
        <img v-if="compass.W" src="storage/metar/compass/w_compass.svg" style="width:200px; height:200px; position: absolute;"/>
        <img v-if="compass.SE" src="storage/metar/compass/se_compass.svg" style="width:200px; height:200px; position: absolute;"/>
        <img v-if="compass.SW" src="storage/metar/compass/sw_compass.svg" style="width:200px; height:200px; position: absolute;"/>
        <img v-if="compass.S" src="storage/metar/compass/s_compass.svg" style="width:200px; height:200px; position: absolute;"/>
         
        <img src="storage/metar/compass/center_circle_white.svg" style="width:200px; height:200px; position: absolute;"/>
        <img v-if="view_center" src="storage/metar/compass/center_circle_blue.svg" style="width:200px; height:200px; position: absolute;"/>

        <img src="storage/metar/compass/secondary_circle_black.svg" style="width:200px; height:200px; position: absolute;"/>
        <img v-if="view_secondary" src="storage/metar/compass/secondary_circle_blue.svg" style="width:200px; height:200px; position: absolute;"/>
    </div>
</template>

<script>
    export default {
        props: {
            direction: { type: Array, default: () => [] },
            center: { type: Number, default: () => 0 },
            secondary: { type: Number, default: () => 0 },
        },
        data() {
            return {
                _direction: [],
                compass: {
                    N: false, NW: false, NE: false, W: false, E: false, SW: false, SE: false, S: false
                },
                view_center: false,
                view_secondary: false
            }
        },
        created() {
            this.changeCompass()
        },
        mounted() {
            this.changeCompass()
        },
        watch: {
            direction: {
                handler: function (val, oldVal) {
                    if(val!=oldVal) {
                        this.changeCompass()
                    }
                }
            },
            center: {
                handler: function (val, oldVal) {
                    if(val!=oldVal) {
                        this.changeCompass()
                    }
                }
            },
            secondary: {
                handler: function (val, oldVal) {
                    if(val!=oldVal) {
                        this.changeCompass()
                    }
                }
            }
        },
        methods: {
            changeCompass() {
                var d = this.direction
                var cen = this.center
                var sec = this.secondary
                this._direction = []

                this._direction = d?d:[]
                this.view_center = cen == 1 ? true : false
                this.view_secondary = sec == 1 ? true : false
                this.compass.N = this._direction.includes('N')
                this.compass.NW = this._direction.includes('NW')
                this.compass.NE = this._direction.includes('NE')
                this.compass.W = this._direction.includes('W')
                this.compass.E = this._direction.includes('E')
                this.compass.SW = this._direction.includes('SW')
                this.compass.SE = this._direction.includes('SE')
                this.compass.S = this._direction.includes('S')
            }
        },
    }
</script>
<style scoped>
</style>